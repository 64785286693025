// 导入 Vue 模块
import Vue from 'vue'
// 导入 Vue Router 模块
import VueRouter from 'vue-router'
// 导入首页组件

const Home = () => import('../views/Home/Home')
const HomePage = () => import('../views/HomePage/HomePage')

const HomeIndex = () => import('../views/HomeIndex/HomeIndex')
const HomeDetails = () => import('../views/HomeDetails/HomeDetails')
const AboutEvent = () => import('../views/AboutEvent/AboutEvent')
const CompetitionArea = () => import('../views/CompetitionArea/CompetitionArea')
const HomeLists = () => import('../views/HomeLists/HomeLists')

const Login = () => import('../views/Login/Login')
const Register = () => import('../views/Register/Register')
const Userxieyi = () => import('../views/Userxieyi/Userxieyi')

const PartnerApplication = () => import('../views/PartnerApplication/PartnerApplication')

const Sou = () => import('../views/Sou/Sou')
const Share = () => import('../views/Share/Share')
const Order = () => import('../views/Order/Order')
const AppDownload = () => import('../views/AppDownload/AppDownload')

const Cont = () => import('../views/Cont/Cont')
const Lis = () => import('../views/Cont/Lis')
const Details = () => import('../views/Cont/Details')
const Payres = () => import('../views/Payres/Payres')
const ContactUs = () => import('../views/ContactUs/ContactUs')

const Partner = () => import('../views/Partner/Partner')
const Pmessage = () => import('../views/Partner/Pmessage')
const Pmessage2 = () => import('../views/Partner/Pmessage2')
const Pindex = () => import('../views/Partner/Pindex')
const Pmy = () => import('../views/Partner/Pmy')
const Editpartner = () => import('../views/Partner/Editpartner')
const Subordinateplayer = () => import('../views/Partner/Subordinateplayer')
const PartnerWithdraw = () => import('../views/Partner/PartnerWithdraw')
const PartnerWithdrawrecord = () => import('../views/Partner/PartnerWithdrawrecord')
const Addpartnerbank = () => import('../views/Partner/Addpartnerbank')
const PartnerContactUs = () => import('../views/Partner/PartnerContactUs')
const PartnerOnlineMessage = () => import('../views/Partner/PartnerOnlineMessage')
const PartnerMymessage = () => import('../views/Partner/PartnerMymessage')
const PartnerFindlist = () => import('../views/Partner/PartnerFindlist')
const PartnerPlayer = () => import('../views/Partner/PartnerPlayer')

const User = () => import('../views/User/User')
const Index = () => import('../views/User/Index')
// const ContactUs = () => import('../views/User/ContactUs')
const AccountBalance = () => import('../views/User/AccountBalance')
const My = () => import('../views/User/My')
const Inform = () => import('../views/User/Inform')
const Inform2 = () => import('../views/User/Inform2')
const EditUserinfo = () => import('../views/User/EditUserinfo')
const OnlineMessage = () => import('../views/User/OnlineMessage')
const Withdraw = () => import('../views/User/Withdraw')
const Withdrawrecord = () => import('../views/User/Withdrawrecord')
const Mymessage = () => import('../views/User/Mymessage')
const Adduserbank = () => import('../views/User/Adduserbank')
const Findlist = () => import('../views/User/Findlist')
const Matchresult = () => import('../views/User/Matchresult')




// 将 Vue Router 插件安装到 Vue 中
Vue.use(VueRouter)
// 创建路由配置对象
const router = new VueRouter({
  mode: 'hash',  
  routes: [
      {
        path: '/',
        redirect: '/homeindex',
      },
      {
        path: '/homeindex',
        component: HomeIndex
      },
      {
        path: '/homepage',
        name: 'HomePage',
        component: HomePage
      },
      {
        path: '/home/:category_id',
        name: 'Home',
        component: Home
      },
      {
        path: '/homelists',
        name: 'HomeLists',
        component: HomeLists
      },
      {
        path: '/homedetails/:sign/:id',
        name: 'HomeDetails',
        component: HomeDetails
      },
      
      {
        path: '/aboutevent/:type/:adtype',
        name: 'AboutEvent',
        component: AboutEvent
      },
      {
        path:'/competitionarea',
        name:'CompetitionArea',
        component:CompetitionArea
      },
      //登入
      {
        path: '/login',
        name: 'Login',
        component: Login
      },
      //登入
      {
        path: '/userxieyi',
        name: 'Userxieyi',
        component: Userxieyi
      },
      //选手报名
      {
        path:'/register',
        name:'Register',
        component:Register
      },
      {
        path:'/payres',
        name:'Payres',
        component:Payres
      },
      {
          path:'/contactus',
          name:'ContactUs',
          component:ContactUs
      },
      //合作商申请
      {
        path:'/partnerapplication',
        name:'PartnerApplication',
        component:PartnerApplication
      },
      //选手搜索
      {
        path: '/sou/:category_id',
        name: 'Sou',
        component: Sou
      },
      //新选手详情
      {
        path:'/share/:id/:category_id',
        name:'Share',
        component:Share
      },
      {
        path:'/order',
        name:Order,
        component:Order
      },
      {
        path:'/appdownload',
        name:'AppDownload',
        component:AppDownload
    },
      {
        path: '/cont',
        component:Cont,
        children:[
          //旧选手列表
          {
            path:'lis',
            name:'Lis',
            component:Lis,
          },
          //旧选手详情
          {
            path:'details/:id',
            name:'Details',
            component:Details,
          },
        ]
      },
      //合作商中心管理
      {
        path:'/partner',
        component:Partner,
        children:[
          //合作商资料（个人资料）
          {
            path:'pindex',
            name:'Pindex',
            component:Pindex,
          },
          //审核通知
          {
            path:'pmessage',
            name:'Pmessage',
            component:Pmessage,
          },
          //站内信
          {
            path:'pmessage2',
            name:'Pmessage2',
            component:Pmessage2,
          },
          //我的
          {
            path:'pmy',
            name:'Pmy',
            component:Pmy,
          },
          //编辑个人资料
          {
            path:'editpartner',
            name:'Editpartner',
            component:Editpartner
          },
          //旗下选手
          {
            path:'subordinateplayer',
            name:'Subordinateplayer',
            component:Subordinateplayer,
          },
          //选手详情
          {
            path:'partnerplayer/:id',
            name:'PartnerPlayer',
            component:PartnerPlayer,
          },
          //提现
          {
            path:'partnerWithdraw',
            name:'PartnerWithdraw',
            component:PartnerWithdraw
          },
          //提现记录
          {
            path:'partnerWithdrawrecord',
            name:'PartnerWithdrawrecord',
            component:PartnerWithdrawrecord,
          },
          //添加银行卡
          {
            path:'addpartnerbank',
            name:'Addpartnerbank',
            component:Addpartnerbank
          },
          //联系我们
          {
            path:'partnercontactus',
            name:'PartnerContactUs',
            component:PartnerContactUs,
          },
          //在线留言
          {
            path:'partneronlinemessage',
            name:'PartnerOnlineMessage',
            component:PartnerOnlineMessage,
          },
          //我的留言
          {
            path:'partnermymessage',
            name:'PartnerMymessage',
            component:PartnerMymessage,
          },
          //收支明细
          {
            path:'partnerfindlist',
            name:'PartnerFindlist',
            component:PartnerFindlist,
          }
        ]
      }
      //选手个人中心管理
      ,{
        path:'/user',
        component:User,
        children:[

          //个人资料
          {
            path:'index',
            name:'Index',
            component:Index
          },
          //联系我们
          {
            path:'contactus',
            name:'ContactUs',
            component:ContactUs,
          },
          {
            path:'accountbalance',
            name:'AccountBalance',
            component:AccountBalance,
          },
          //我的
          {
            path:'my',
            name:'My',
            component:My,
          },
          // 站内信
          {
            path:'inform2',
            name:'inform2',
            component:Inform2,
          },
          //审核通知
          {
            path:'inform',
            name:'Inform',
            component:Inform,
          },
          //个人资料编辑页
          {
            path:'edituserinfo',
            name:'EditUserinfo',
            component:EditUserinfo
          },
          //在线留言
          {
            path:'onlinemessage',
            nane:'onlinemessage',
            component:OnlineMessage
          },
          //提现
          {
            path:'withdraw',
            name:'Withdraw',
            component:Withdraw
          },
          //提现记录
          {
            path:'withdrawrecord',
            name:'Withdrawrecord',
            component:Withdrawrecord,
          },
          //我的留言
          {
            path:'mymessage',
            name:'Mymessage',
            component:Mymessage
          }
          //添加银行卡
          ,{
            path:'adduserbank',
            name:'Adduserbank',
            component:Adduserbank
          },
          //收支明细
          {
            path:'findlist',
            name:'Findlist',
            component:Findlist
          },
          //我的赛况
          {
            path:'matchresult',
            name:'Matchresult',
            component:Matchresult,
          },
        ]
      }
  ]
})
// 导出路由配置对象
export default router