<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
/* 全局 */
.box{
		width: 1300px;
		margin: 0 auto;
	}
.con-right{
		width: 930px;
		height: 1620px;
		margin-left: 220px;
		/* background-color: #fff; */
		border:1px solid #000;
	}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
a, a:hover{
  color: inherit;
  text-decoration: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html, body {
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
}
.fl{
  float: left;
}
.fr{
  float: right;
}
.clearfix{
  clear:both;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.container{
	margin: 0 !important;
	padding: 0 !important;
}
@media screen and (min-width: 428px) {
	.page{
			 background-color: #f8f8f8 !important;
		}
}
@media screen and (max-width: 768px) {
  .lh-mobile-banner, .lh-footer.other .lh-wapAbout-h2 {
    display: block;
  }
  .lh-banner, .lh-about-main:before, .lh-about-main:after, .lh-about-main .line:before, .lh-about-main .line:after {
    display: none;
  }
  .container{
    padding-top: 0px;
  }
}
</style>